  
import React, {Component} from 'react';
import Routes from './routs/Routes';

class App extends Component {
 
  render(){
    return (
      <div>
        <Routes />
      </div>
    );
  }
}

export default App;
