import React from 'react';
import close from '../images/SVG/x-close.svg';

import {Link} from 'react-router-dom';
import logo from '../images/SVG/logo.svg'

const Impresum = (props) => {
  return (
        <div className="impresum ">
            <div className="custom-container">
                <div className=" flex-row">
               
                        <img className="logo" src={logo} alt="Tamara Gisler" onClick={props.showImpresum}/>
                        <p className="close-window" onClick={props.showImpresum}> <img src={close}  alt="Tamara Gisler"/></p>
                </div>
                <h3 className="component-title">IMPRESSUM</h3>

                <div className="inpresum-cols">
                    <div className="i-col">
                        <p>BETREIBERIN UND INHALTLICH <br/> VERANTWORTLICH</p>
                        <p>Tamara Gisler – Homöopathie <br/> Breitengasse 3 <br/> CH 6463 Bürglen</p>
                        <br/>
                        <p>Telefon: <a href="tel:+41 77 414 33 98">+41 77 414 33 98</a> </p>
                        <a href="mailto:info@tamaragisler.ch">info@tamaragisler.ch</a>
                    </div>

                    <div className="i-col">
                        <p>KONZEPT UND DESIGN</p>
                        <p>Blatthirsch GmbH <br/> Hellgasse 23 <br/> CH-6460 Altdorf <a href="https://www.blatthirsch.ch/"> <br/> www.blatthirsch.ch </a> </p>
                        <br/>
                        <p>Telefon: <a href="tel:+41 41 855 06 06">+41 41 855 06 06</a> </p>
                        <a href="mailto:info@blatthirsch.ch">info@blatthirsch.ch</a>
                    </div>

                    <div className="i-col">
                        <p>PROGRAMMIERUNG <br/> BINARY one GmbH</p>
                        <p>Marktgasse 4 <br/> CH-6460 Altdorf <a href="https://www.binaryone.ch/"> <br/> www.binaryone.ch </a></p>
                        <br/>
                        <p>Telefon: <a href="tel: +41 41 871 31 31">+41 41 871 31 31</a> </p>
                        <a href="mailto:one@binaryone.ch">one@binaryone.ch</a>
                    </div>
                </div>

                <p className="impresum-row">
                    ONLINE-STREITBEILEGUNG <br/>
                    Die Europäische Kommission stellt unter https://ec.europa.eu/consumers/odr/ eine Plattform zur OnlineStreitbeilegung bereit, die Verbraucher für die Beilegung einer Streitigkeit nutzen können und auf der weitere Informationen zum Thema Streitschlichtung zu finden sind.
                </p>

                <p className="impresum-row">
                    AUSSERGERICHTLICHE STREITBEILEGUNG <br/>
                    Wir sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit mit einem Verbraucher an einem
                    Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </p>
            </div>
        </div>
    )
}

export default Impresum;