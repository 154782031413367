import React from 'react';
import blume from '../images/img3.png';
import tamaraImg from '../images/tamara2.jpg';


const ZurPerson = () => {

  return (
    <div className="zurPerson" >
      <h2 id="zurPerson " className="navigation-title t1"> ZUR PERSON</h2>
      <h3 className="component-title t1">INDIVIDUELL <br /> BEGLEITEND</h3>

      <div className="zur-person-container">
        <div className="zur-person-column t1" >
          <h4 className="zur-person-title">AUSBILDUNG</h4>
          <p>Ich nutze meine langjährige Erfahrung im Gesundheitswesen und das Wissen aus den Ausbildungen Medizinische Praxisassistentin,
            Dipl. Farbtherapeutin AGP und Dipl. Homöopathin SHS.
          </p>
        </div>

        <div className="zur-person-column t2">
          <h4 className="zur-person-title">WERTE</h4>
          <p>Ich begegne meinem Gegenüber wertfrei, empathisch, aufrichtig und vertrauensvoll. Jeder einzelne Mensch ist ein Wunder der Natur,
            ein Individuum – einzigartig wunderbar.
          </p>
        </div>

        <div className="zur-person-column t3">
          <h4 className="zur-person-title">ZIELE</h4>
          <p>Ich begleite Menschen ganzheitlich. Ich mache mich mit ihnen auf den Weg gesund und selbstbestimmt durchs Leben zu schreiten und
            werde ihnen auch auf Umwegen zur Seite stehen.
          </p>
        </div>
        <br/>
      </div>

      <div className="zur-person-images t2">
        <div className="img-container">
          <img src={tamaraImg} className="zur-person-image" alt="Tamara Gisler" />
        </div>
        <div className="zur-person-rectangle"></div>
        <div className="flower-container t2">
          <img className="animated" className="flower-green" src={blume} alt="Tamara Gisler" />
        </div>
      </div>

    </div>
  )
}

export default ZurPerson;