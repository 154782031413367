import React, { Component } from 'react';
import Impresum from '../layouts/Impresum';
import { animateScroll as scroll } from "react-scroll";

import logo from '../images/SVG/logo-main.svg';
import fb from '../images/ICONS/facebook-brands.svg';
import insta from '../images/ICONS/instagram-brands.svg'; 
import arrow from '../images/ICONS/arrow-up.png';

class Footer extends Component {
    state = {
        ImpresumOpen: false,
      };
      constructor(props) {
        super(props);
        this.showImpresum = this.showImpresum.bind(this)
      } 
    
      showImpresum(){
        let Impresum = document.getElementById("impresum");
        let body = document.getElementById("body");

        if(this.state.ImpresumOpen === false){
         Impresum.style.display= "block";   
            body.style.overflowY = "hidden";
        Impresum.style.animation="fadeIn .7s ease-in-out";
        this.setState((state) => {
            return {ImpresumOpen: state.ImpresumOpen = true};
          });
        }else{
            Impresum.style.display= "none";
            body.style.overflowY = "auto";
            this.setState((state) => {
                return {ImpresumOpen: state.ImpresumOpen = false};
              });
        }
      }


    scrollToTop = () => {
        scroll.scrollToTop();
      };

    render(){
        return (
                <div id="footer" className="footer">
                     <div id="impresum" className="impresum-modal">
                        <Impresum showImpresum={this.showImpresum} />
                    </div>
                    <div className="custom-container">
                        <div className="footer-top-container">
                            <div className="custom-container">
                                <div onClick={this.scrollToTop} className="scroll-to-top"><img className="arrow-up"  src={arrow} alt="Tamara Gisler"/> Nach oben</div>
                            </div>
                        
                            <div className="logo-container">
                                <img className="logo" src={logo} alt="Tamara Gisler"/>
                            </div>
                        </div>

                        <div className="footer-container">
                            <div className="footer-col">
                                <div>
                                    <h4>PRAXIS</h4>
                                    <p>
                                        Villa Vita <br/>
                                        Tamara Gisler – Homöopathie<br/>
                                        Seedorferstrasse 6 <br/>
                                        6460 Altdorf, Uri
                                    </p>    
                                </div>           
                                <br/>
                                <div><br/></div>      
                                <a className="underline" target="_blank" rel="noreferrer" href="https://goo.gl/maps/g16K7Uja4WmbGENv7">Lageplan</a>
                            </div> 

                            <div className="footer-col">
                                <div>
                                    <h4>TERMINE</h4>
                                    <p>Montag bis Freitag<br/> nach Vereinbarung</p> <br/>
                                </div>
                                <div>
                                    <a href="tel:077 414 33 98">T 077 414 33 98</a><br/>
                                    <a href="mailto:info@tamaragisler.ch">info@tamaragisler.ch</a><br/>
                                </div>
                            </div> 

                            <div className="footer-col">
                            <div>
                                    <div className="underline links-modal impresum-link" onClick={this.showImpresum}>Impressum </div>
                                   {/*  <a className="underline links-modal" >Datenschutz</a> */}
                            </div>
                            <div>
                                  <p>Folgen Sie mir auf Social Media:</p>
                                 <div className="social-icons">
                                    <a href="https://www.instagram.com/homoeopathie_tamara_gisler/">
                                        <img src={insta} alt="Tamara Gisler"/>
                                    </a>
                                    <a href="https://www.facebook.com/TamaraGisler">
                                        <img src={fb} alt="Tamara Gisler" />
                                    </a>
                                </div> 
                            </div>
        
                            <p>©Tamara Gisler.  All rights reserved.</p>
                            </div> 

                        </div>
                    </div>
                </div>
            )
        }
}

export default Footer;