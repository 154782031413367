import React, { Component } from 'react';

import img from '../images/tamara1.jpg'
import img1 from '../images/img2.png';
import ContactForm from '../layouts/ContactForm';

class Philosophie extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ContactFormOpen: false,
        }
        this.showForm = this.showForm.bind(this);
    }

    showForm() {
        let ContactForm = document.getElementById("contact-form");
        let body = document.getElementById("body");

        if (this.state.ContactFormOpen === false) {
            ContactForm.style.display = "block";
            body.style.overflowY = "hidden";
            this.setState((state) => {
                return { ContactFormOpen: state.ContactFormOpen = true };
            });
            ContactForm.style.animation = "fadeIn .5s ease-in-out";
        } else {
            ContactForm.style.display = "none";
            body.style.overflowY = "auto";
            this.setState((state) => {
                return { ContactFormOpen: state.ContactFormOpen = false };
            });
        }
    }



    render() {
        return (
            <div className="philosophie">
                <img src={img} className="intro-img " alt="Tamara Gisler" />
                

                <div id="philosophie">
                    <div className="t1">
                         <h1 >Tamara Gisler - Homöopathie in Uri</h1>
                        <h3 className="component-title">ACHTSAM<br />VOLLUMFÄNG&shy;LICH<br />TIEF GEHEND</h3>
                    </div>
                    <div className="grid">
                        <p className="philosophie-text t1">Mit der Homöopathie, dieser alten Heilmethode, wird der Mensch in
                            seiner Gesamtheit erfasst. Körper und Geist gehören zusammen und
                            werden nicht getrennt voneinander behandelt. Homöopathische Arzneimittel beinhalten natürliche Substanzen. Das können pflanzliche,
                            mineralische oder tierische Bestandteile sein, welche stark verdünnt
                            verabreicht werden. Das Mittel wirkt wie ein Reiz, der die Selbstheilungskräfte aktiviert und somit ihre Lebenskraft wieder ins Gleichgewicht bringt.
                            <br /> <br />
                            <small className="small-Text">Tamara Gisler, dipl. Homöopathin SHS</small> <br />
                            <small className="small-Text">T. 077 414 33 98</small>
                        </p>
                    </div>
                </div>

                <div id="contact-form" className="contact-form-show">
                    <ContactForm showForm={this.showForm} />
                </div>

                <div className="contact-text-container">

                    <div className="grid t1">
                        <div className="contact-title" onClick={this.showForm}>
                            <h2 className="contact-title"> KONTAKTFORMULAR </h2>
                        </div>
                    </div>
                    <div className="t1">
                        <div className="contact-text">«Similia similibus curentur – <br /> Ähnliches werde mit Ähnlichem geheilt.»</div>
                        <p className="contact-text-author">Samuel Hahnemann, Begründer der Homöopathie</p>
                    </div>
                </div>

                <div className="flower-container t2">
                    <img className="flower-yellow" src={img1} alt="Tamara Gisler" />
                </div>

                <div className="imgGroup t2">
                    <div className="green-rectangle-bg"></div>
                    <div className="contact-img1"></div>
                    <div className="contact-img2"></div>
                </div>
            </div>

        )
    }
}

export default Philosophie;