import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import close from '../images/SVG/x-close.svg';
import logo from '../images/SVG/logo.svg';

emailjs.init("-umz_uPzWA3QnesbX");

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            btn: "NACHRICHT SENDEN",
        }

    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // First Name
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "Bitte ausfüllen";
        }

        if (typeof fields["first_name"] !== "undefined") {
            if (!fields["first_name"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["first_name"] = "Gib bitte Buchstaben ein";
            }
        }

        // Last Name
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "Bitte ausfüllen";
        }

        if (typeof fields["last_name"] !== "undefined") {
            if (!fields["last_name"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["last_name"] = "Gib bitte Buchstaben ein";
            }
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Bitte ausfüllen";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "E-mail Adresse ist nicht gültig";
            }
        }
        // tel
         if(!fields["tel"]){
                formIsValid = false;
                errors["tel"] = "Bitte ausfüllen";
                } 

        if (typeof fields["tel"] !== "undefined") {
            //match only swiss numbers
            if (!fields["tel"].match(/(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/)) {
                formIsValid = false;
                errors["tel"] = "Gib bitte Zahlen ein";
            }
        }

        //message
        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Bitte ausfüllen";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    contactSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {

            emailjs.sendForm("service_xy2jeo8","template_pn24qs1", e.target)
                .then(() => {
                    document.getElementById("form").reset();
                    document.getElementById("textarea").value = '';
                    this.setState({
                        fields: {},
                        errors: {},
                        btn: "NACHRICHT GESENDET",
                    });
                }, (error) => {
                    console.log(error.text);
                });

        }
    }


    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    render() {
        return (
            <div className="contact-form ">
                <div className="custom-container">
                    <div className=" flex-row">

                        <img className="logo" src={logo} alt="Tamara Gisler" onClick={this.props.showForm} />
                        <p className="close-window" onClick={this.props.showForm}> <img src={close} alt="Tamara Gisler" /> </p>
                    </div>
                    <h3 className="component-title">KONTAKTIEREN <br /> SIE MICH</h3>

                    <form id="form" onSubmit={this.contactSubmit.bind(this)} method="post" >
                        <label className="grid" htmlFor="first_name">
                            <h2> VORNAME</h2>
                            <div className="inputWrapper">
                                <input type="text" name="first_name" onChange={this.handleChange.bind(this, "first_name")} value={this.state.fields["first_name"]} />
                                <span className="errorMessage">{this.state.errors["first_name"]}</span>
                            </div>
                        </label>
                        <label className="grid" htmlFor="last_name" >
                            <h2>NAME</h2>
                            <div className="inputWrapper">
                                <input type="text" name="last_name" onChange={this.handleChange.bind(this, "last_name")} value={this.state.fields["last_name"]} />
                                <span className="errorMessage">{this.state.errors["last_name"]}</span>
                            </div>
                        </label>
                        <label className="grid" htmlFor="email">
                            <h2> EMAIL</h2>
                            <div className="inputWrapper">
                                <input  type="email" name="email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} /> 
                                <span className="errorMessage">{this.state.errors["email"]}</span>
                            </div>
                        </label>
                        <label className="grid" htmlFor="tel">
                            <h2> TEL</h2>
                            <div className="inputWrapper">
                                <input type="tel" name="tel" onChange={this.handleChange.bind(this, "tel")} value={this.state.fields["tel"]} />
                                <span className="errorMessage">{this.state.errors["tel"]}</span>
                            </div>

                        </label>
                        <label className="grid" htmlFor="message">
                            <h2> NACHRICHT <span>Bitte alle Felder ausfüllen.</span></h2>
                            <div className="inputWrapper">
                                <textarea id="textarea" name="message" onChange={this.handleChange.bind(this, "message")} value={this.state.fields["message"]}></textarea>
                                <span className="errorMessage">{this.state.errors["message"]}</span>
                            </div>
                        </label>
                        <div className="grid">
                            <button className="submit-btn" type="submit" name="submit"><h2 className="contact-form-title">{this.state.btn}</h2></button>
                        </div>
                    </form>

                    <div className="grid">


                    <div className="footer-container">
                            <div className="footer-col">
                                <div>
                                    <h4>PRAXIS</h4>
                                    <p>
                                        Villa Vita <br/>
                                        Tamara Gisler – Homöopathie<br/>
                                        Seedorferstrasse 6 <br/>
                                        6460 Altdorf, Uri
                                    </p>    
                                </div>                                
                                <br/>
                                <a className="underline" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Breitengasse+3,+6463+B%C3%BCrglen/@46.8758606,8.6678839,17z/data=!3m1!4b1!4m5!3m4!1s0x478543e553b4350d:0x6c5ad053ad173e9e!8m2!3d46.875857!4d8.6700726">Lageplan</a><br/>
                            </div> 

                            <div className="footer-col">
                                <div>
                                    <h4>TERMINE</h4>
                                    <p>Montag bis Freitag <br/> nach Vereinbarung</p> <br/>
                                </div>
                        
                                <div>
                                    <a href="tel:077 414 33 98">T 077 414 33 98</a><br/>
                                    <a href="mailto:info@tamaragisler.ch">info@tamaragisler.ch</a><br/>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactForm;


//onSubmit={this.contactSubmit.bind(this)}