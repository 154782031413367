import React, {Component} from 'react';
import logo from '../images/SVG/logo.svg';
import { Link, animateScroll as scroll } from "react-scroll";

import close from '../images/SVG/x-close.svg';
import menu from '../images/SVG/mobile-menu.svg';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this)
  } 

  toggleMenu(){
    let open = document.getElementById("open");
    let close = document.getElementById("close");
    let navi = document.getElementById("mobile-navi");
    let body = document.getElementById("body");
    
    if(this.state.menuOpen === false){
      open.style.display= "none"
      navi.style.display= "block";
      close.style.display = "block";
      body.style.overflowY = "hidden";
      this.setState({menuOpen: true});
    }
    else{
      open.style.display= "block"
      navi.style.display= "none";
      close.style.display = "none"; 
      body.style.overflowY = "auto";
      this.setState({menuOpen: false});
    }
  }

  render()  {
    
    return (
            <div className="custom-container header-navigation" id="header" >
             <img className="logo" src={logo} alt="Tamara Gisler"/>
                <nav>
                  <ul className="desktop-ul">
                    <li ><Link spy={true} smooth={true} offset={-70} duration={500} to="philosophie">Philosophie</Link></li>
                    <li><Link spy={true} smooth={true} offset={-70} duration={1000} to="zurPerson">Zur Person</Link></li>
                    <li><Link spy={true} smooth={true} offset={-70} duration={1500} to="kosten">Kosten </Link></li>
                    <li><Link spy={true} smooth={true} offset={-70} duration={2000} to="footer">Kontakt </Link></li>
                  </ul>
                  <div id="menu">
                      <div id="openwrapper">
                        <img id="open" src={menu} alt="Tamara Gisler" onClick={this.toggleMenu} />
                      </div>
                      <img id="close" src={close} alt="Tamara Gisler" onClick={this.toggleMenu} />
                  </div>
                </nav>

                <div id="mobile-navi" className="mobile-navi">
                <ul>
                    <Link spy={true} smooth={true} offset={-70} duration={500} to="philosophie"><li onClick={this.toggleMenu}>Philosophie</li></Link>
                    <Link spy={true} smooth={true} offset={-70} duration={1000} to="zurPerson"> <li onClick={this.toggleMenu}>Zur Person</li></Link>
                    <Link spy={true} smooth={true} offset={-70} duration={1500} to="kosten"><li onClick={this.toggleMenu}>Kosten </li></Link>
                    <Link spy={true} smooth={true} offset={-70} duration={2000} to="footer"><li onClick={this.toggleMenu}>Kontakt </li></Link>
                  </ul>
                </div>
            </div>
        )
    }    
}

export default Header;