import React from 'react';
import { useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import Favicon from 'react-favicon'
import { gsap, ScrollTrigger} from "gsap/all";
import Header from '../components/Header';
import Philosophie from '../components/Philosophie';
import ZurPerson from '../components/ZurPerson';
import Kosten from '../components/Kosten';
import Footer from '../components/Footer';

import IntroHandWriting from './IntroHandwriting';

import '../scss/app.scss';

const HomePage = () => {

  gsap.registerPlugin(ScrollTrigger);

  //animation for yellow flower
  useEffect(() => {
    const animations = gsap.utils.toArray('.zoomIn');
    const mediaQuery = window.matchMedia("(min-width: 769px)");

    if(mediaQuery.matches) {
    animations.forEach((zoomInAnim, i) => {
      const anim = gsap.fromTo(zoomInAnim, { autoAlpha:0, scaleX:0, scaleY:.5}, { autoAlpha:1,  duration: .5,  scaleX:1, scaleY:1});
      ScrollTrigger.create({
        trigger: zoomInAnim,
        animation: anim,
        toggleActions: 'restart none none reverse',
        start:"bottom 80%",
      });
    });
  }

  }, [])

  
  //animation for green l
  useEffect(() => {
    const animations = gsap.utils.toArray('.zoomInGreenFlower');
    const mediaQuery = window.matchMedia("(min-width: 769px)");

    if(mediaQuery.matches) {
    animations.forEach((zoomInAnim, i) => {
      const anim = gsap.fromTo(zoomInAnim, { scaleX:.7, scaleY:.5, x:0}, {duration: 1, scaleX:1, scaleY:1, x:200});
      ScrollTrigger.create({
        trigger: zoomInAnim,
        animation: anim,
        toggleActions: 'restart none none reverse',
        start:"top 80%",
      });
    });
  }

  }, [])

  //animation for text box text2
  useEffect(() => {
    const animations = gsap.utils.toArray('.t2');
    const mediaQuery = window.matchMedia("(min-width: 769px)");

    if(mediaQuery.matches) {
    animations.forEach((t2, i) => {
      const anim = gsap.fromTo(t2,{ y: 60}, {duration: 0.5, y: 0});
      ScrollTrigger.create({
        trigger: t2,
        animation: anim,
        toggleActions: 'restart none none reverse',
        start:"center 80%",
      });
    });
  }

  }, [])

  //animation for text box text3
  useEffect(() => {
    const animations = gsap.utils.toArray('.t3');
    const mediaQuery = window.matchMedia("(min-width: 769px)");

    if(mediaQuery.matches) {
    animations.forEach((box, i) => {
      const anim = gsap.fromTo(box,{ y: 60}, {duration: 0.5, y: 0});
      ScrollTrigger.create({
        trigger: box,
        animation: anim,
        toggleActions: 'restart none none reverse',
        start:"bottom 80%",
      });
    });
  }

  }, [])

    //animation for text box text3
    useEffect(() => {
      const animations = gsap.utils.toArray('.t1');
      const mediaQuery = window.matchMedia("(min-width: 769px)");
  
      if(mediaQuery.matches) {
      animations.forEach((box, i) => {
        const anim = gsap.fromTo(box,{ y: 60}, {duration: 0.5, y: 0});
        ScrollTrigger.create({
          trigger: box,
          animation: anim,
          toggleActions: 'restart none none reverse',
          start:"top 80%",
        });
      });
    }
  
    }, [])


    return (
      <div  className="body-bg">
        <Favicon url='./favicon.ico' />
        <MetaTags>
            <title>Tamara Gisler - Homöopathie</title>
            <meta name="description" content="Tamara Gisler Homöopathie in Uri: Achtsam, vollumfänglich und tief gehend" />
            <meta property="og:title" content="Tamara Gisler - Homöopathie" />
          </MetaTags>
        <IntroHandWriting/>
          <Header/>
          <div className="bg-green">
            <div className="custom-container2">
                      <Philosophie/> 
                      <ZurPerson/> 
                      <Kosten/>
                  <div className="element"></div>
            </div>
          </div>
          <Footer/>
      </div>
    );
  
}

export default HomePage;
