import React, { Component } from 'react';

import logooben from '../images/SVG/Logo-Oben.svg';
import logounten from '../images/SVG/Logo-Unten.svg';
import homeopatie from '../images/SVG/Homoopathie.svg';
import logo from '../images/SVG/logo.svg';
import leaves from '../images/SVG/leaves.svg';
import leaveUnten from '../images/SVG/leave-unten.svg';


class Intro extends Component {

  constructor() {
    super();
    this.animBoxMoveDown();
    this.animIntroDown();
    this.animLeaves();
    this.skipAnimation();
  }

  animBoxMoveDown() {
    setTimeout(function () {
      let animBox = document.getElementById("animBox");
      animBox.style.animation = "moveDown 1.5s ease-in-out forwards";
    }, 3000);
  }

  animIntroDown() {
    setTimeout(function () {
      let animIntro = document.getElementById("animIntro");
      animIntro.style.animation = "moveScreenDown .3s ease-in-out forwards";
      let body = document.getElementById("body");
      body.style.overflowY = "auto";
    }, 4000);
  }

  animLeaves() {
    setTimeout(function () {
      let leaveAnim2 = document.getElementById("leave-anim2");
      let leaveAnim = document.getElementById("leave-anim");
      leaveAnim2.style.animation = "fadeIn 1s ease-in-out forwards";
      leaveAnim.style.animation = "fadeIn 1s ease-in-out forwards";
    }, 1500);
  }

  skipAnimation() {
    setTimeout(function () {
      let body = document.getElementById("body");
      let animIntro = document.getElementById("animIntro");
      body.style.overflowY = "auto";
      animIntro.style.zIndex = "-999";
      animIntro.style.display = "none";
    }, 4300);
  }

  render() {
    return (
      <div id="animIntro" className="intro" onClick={this.skipAnimation}>
        <div id="animBox" className="anim-box">
          <div className="logo-top-part">
            <svg className="line-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.46 119.58">
              <path className="path" id="A" d="M.69,119.58A125.44,125.44,0,0,1,112.46.69s0-.69-.06-.69A126.13,126.13,0,0,0,0,119.55Z" />
            </svg>
            <img id="leave-anim2" className="leave-top leave-anim" src={leaves} alt="Tamara Gisler" />
          </div>
          <img className="homeo" src={homeopatie} alt="Tamara Gisler" />
          <img className="logo-amim-intro" src={logo} alt="Tamara Gisler" />
          <div className="logo-bottom-part">
            <img id="leave-anim" className="leave-bottom leave-anim" src={leaveUnten} alt="Tamara Gisler" />
            <svg className="line-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.89 67.14">
              <path className="path" id="A" d="M85.26,0A125.74,125.74,0,0,1,0,66.46l.11.68A126.44,126.44,0,0,0,85.89.3Z" />
            </svg>
          </div>
        </div>
        {/*     <div className="rectangle-hide"></div> */}
      </div>
    );
  }
}

export default Intro;
