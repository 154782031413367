import React from 'react';


const Kosten = () => {
  return (
        <div className="kosten">
        <div className=" t1">
            <h2 id="kosten" className="navigation-title">KOSTEN</h2>
            <div className="kosten-row">
                <p>Anamnese, Repertorisation, telefonische Beratung <span>(pro Stunde)</span></p>
                <p>150.–</p>
            </div>
            <div className="kosten-row">
                <p>Notfallzuschlag an Wochenenden und Feiertagen pro Konsultation</p>
                <p>40.–</p>
            </div>
            <div className="notice-kosten">Nicht einhaltbare Konsultationen müssen mindestens 24 Stunden vorher abgesagt werden. </div>
        </div>
        <div className="kosten-container t1">
                <p> <strong>Kostenübernahme durch die Krankenkassen</strong> <br/> Die Kostenübernahme der homöopathischen Behandlung erfolgt über eine bestehende 
                    Zusatzversicherung. 
                </p>
                <p>Der Anteil der Übernahme durch die Krankenkasse pro Behandlung ist unterschiedlich. Informieren Sie sich persönlich bei Ihrer 
                    Krankenkasse über die genauen Leistungen Ihrer Versicherung
                </p>
                <p>ZSR-Nummer: A782063</p>
        </div>
        </div>
    )
}

export default Kosten;